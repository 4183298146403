.custom-marker-cluster-green {
  background: #15803d;
  border: 3px solid #565353;
  border-radius: 50%;
  color: #ededed;
  height: 40px;
  line-height: 28px;
  text-align: center;
  width: 40px;
}

.custom-marker-cluster-red {
  background: #b91c1c;
  border: 3px solid #565353;
  border-radius: 50%;
  color: #ededed;
  height: 40px;
  line-height: 28px;
  text-align: center;
  width: 40px;
}

.custom-marker-cluster-red-green {
  background: linear-gradient(90deg, #b91c1c 50%, #008001 50%);
  border: 3px solid #565353;
  border-radius: 50%;
  color: #ededed;
  height: 40px;
  line-height: 28px;
  text-align: center;
  width: 40px;
  font-weight: bold;
  font-size: 16px;
}

.leaflet-popup-content {
  //width: auto!important;
  margin: 10px;
}

.leaflet-popup-content-wrapper {
  background: rgb(17,25,36);
}
.leaflet-popup-tip {
  background: rgb(36,54,78);
  /*display: none; */
}

.leaflet-control-attribution {
  display: none;
}

/* Map dark mode */
:root {
  --leaflet-tile-filter: brightness(0.65) invert(1) contrast(3) hue-rotate(200deg) saturate(0.4) brightness(0.5);
}

.leaflet-tile {
  filter: var(--leaflet-tile-filter, none);
}

.leaflet-container {
  background: #303030;
}

.leaflet-control-zoom-in {
  background: #24364e!important;
  border-bottom-color: #212c38!important;
  color:#d1d5db!important;
}

.leaflet-control-zoom-out {
  background: rgb(36,54,78)!important;
  color:#d1d5db!important;
}

.leaflet-control-layers-toggle {
  background: rgb(36,54,78)!important;
  display:none;
}

.leaflet-tooltip {
  background: rgb(36,54,78)!important;
  border:none;
  color:white
}

.leaflet-tooltip-right:before {
  border-right-color: rgb(36,54,78)!important;
}

.leaflet-tooltip-left:before {
  border-left-color: rgb(36,54,78)!important;
}